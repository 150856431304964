import {
    DYNAMIC_TOKENS,
    FOCUSES,
    FOCUS_TOKENS,
    RESPONSIVENESS,
    RESPONSIVE_STYLES_BREAKPOINTS,
    RESPONSIVE_TOKENS,
    SELECTORS,
    RESPONSIVE_AND_SELECTORS,
    RESPONSIVE_AND_SELECTORS_TOKENS,
    SELECTORS_TOKENS,
} from '../../atoms/constants';
import { Breakpoints } from '../../atoms/types';
import { semanticTokens } from '../../shared/themeVars';
import { mapConditionalStyles } from '../../atoms/styles.ve.css';
import { Display, ResponsiveDisplayType, ResponsiveBooleanType, BoxType, TypeSelectors } from './types';
import { tokenClasses } from './styles.ve.css';

export const generateDisplay = (hidden: ResponsiveBooleanType, display: ResponsiveDisplayType = 'initial') => {
    if (typeof hidden === 'object') {
        const isDisplayObject = typeof display === 'object';

        const displayFirstKey = Object.keys(display)[0] as keyof Breakpoints;
        let currentDisplay: Display = isDisplayObject ? (display[displayFirstKey] as Display) : display;
        return Object.keys(RESPONSIVENESS).reduce(
            (pre, cur) => {
                if (isDisplayObject && display[cur as keyof Breakpoints]) {
                    currentDisplay = display[cur as keyof Breakpoints] as Display;
                }
                if (hidden[cur as keyof Breakpoints]) {
                    pre[cur as keyof Breakpoints] = 'none';
                } else if (hidden[cur as keyof Breakpoints] === undefined && cur !== displayFirstKey) {
                    delete pre[cur as keyof Breakpoints];
                } else {
                    pre[cur as keyof Breakpoints] = currentDisplay;
                }
                return pre;
            },
            isDisplayObject ? display : { default: display }
        );
    }

    return hidden ? 'none' : display;
};

const generateSelectorObject = (
    prop: TypeSelectors[keyof TypeSelectors],
    defaultValue: TypeSelectors[keyof TypeSelectors]
) => {
    if (prop) {
        if (typeof prop === 'object') {
            return {
                default: prop.default || defaultValue,
                // || prop.default is required to override Sass defaults.
                hover: prop.hover || prop.default,
                active: prop.active,
                focus: prop.focus,
            };
        }
        return {
            default: prop,
            hover: prop,
        };
    }

    return undefined;
};

export const linkStyling = ({ color, decoration }: TypeSelectors) => {
    const linkColor = generateSelectorObject(color, 'linkPrimary');
    const linkDecoration = generateSelectorObject(decoration, 'none');

    return {
        color: linkColor as BoxType['color'],
        decoration: linkDecoration as BoxType['decoration'],
    };
};

export const getConditionsByKey = (key) => {
    if (Object.keys(SELECTORS_TOKENS).includes(key)) {
        return SELECTORS;
    } else if (Object.keys(RESPONSIVE_TOKENS).includes(key)) {
        return RESPONSIVE_STYLES_BREAKPOINTS;
    } else if (Object.keys(FOCUS_TOKENS).includes(key)) {
        return FOCUSES;
    } else if (Object.keys(RESPONSIVE_AND_SELECTORS_TOKENS).includes(key)) {
        return RESPONSIVE_AND_SELECTORS;
    }

    return null;
};

export const generateDynamicClasses = (restProps: Record<string, any>): string => {
    const containerClassNames: string[] = [];

    Object.keys(restProps).forEach((prop) => {
        const conditions = getConditionsByKey(prop);
        const CLASS_SUFFIX = 'Class';
        const name = DYNAMIC_TOKENS[prop];

        if (conditions && typeof restProps[prop] === 'object') {
            Object.keys(conditions).forEach((condition) => {
                if (restProps[prop][condition]) {
                    containerClassNames.push(tokenClasses[`${name}${conditions[condition]}${CLASS_SUFFIX}`]);
                }
            });
        } else {
            containerClassNames.push(tokenClasses[`${name}${CLASS_SUFFIX}`]);
        }
    });

    return containerClassNames.join(' ');
};

export const convertSemanticTokens = (props) => {
    const styles = {};
    for (const key in props) {
        if (typeof props[key] === 'object') {
            styles[key] = mapConditionalStyles(props[key], (value) => semanticTokens[value] || value);
        } else if (Object.keys(semanticTokens).includes(props[key])) {
            styles[key] = semanticTokens[props[key]] || props[key];
        } else {
            styles[key] = props[key];
        }
    }

    return styles;
};
