import { getConditionsByKey, linkStyling } from '../components/Box/helpers';
import { tokenVars } from '../components/Box/styles.ve.css';
import { tokens } from '../theme.ve.css';
import { CSS_NUMERIC_VALUES, DYNAMIC_TOKENS, RESPONSIVE_STYLES_BREAKPOINTS, THEME_TOKEN } from './constants';
import { Styles, styles } from './styles.ve.css';
import { Breakpoints, MapTypes, SplitProps } from './types';

export const atoms = (stylingProps: Styles) => styles(stylingProps);

export const isAtomsProp = (key: any): boolean => styles.properties.has(key);

const styleIsNotValidError = (prop, value, options) => {
    throw new Error(
        `"${prop}" has no value '${value}'. Possible values are ${Object.keys(options).map((token) => `${token} `)}`
    );
};

export const generateInlineVars = (props) => {
    const vars: MapTypes = {};
    const varsTokens: MapTypes = {};

    for (const key in props) {
        const themeTokenKey = THEME_TOKEN[key];
        const themeToken = tokens[themeTokenKey];

        if (props[key] !== undefined) {
            if (typeof props[key] === 'object') {
                const dynamicProps = covertObjectKeysToVars(props[key], key);
                Object.keys(dynamicProps).forEach((prop) => {
                    if (themeToken) {
                        const themeTokenValue = themeToken[dynamicProps[prop]];
                        const isValidTokenTheme = !!themeTokenValue;
                        if (isValidTokenTheme) {
                            vars[tokenVars[prop]] = themeToken[dynamicProps[prop]];
                            varsTokens[key] = props[key];
                        } else {
                            styleIsNotValidError(key, dynamicProps[prop], themeToken);
                        }
                    } else {
                        vars[tokenVars[prop]] = dynamicProps[prop];
                        varsTokens[key] = props[key];
                    }
                });
            } else if (tokens[themeTokenKey] && themeToken[props[key]]) {
                const varName = tokenVars[DYNAMIC_TOKENS[key]];

                vars[varName] = themeToken[props[key]];
                varsTokens[key] = props[key];
            } else {
                if (themeTokenKey && props[key]) {
                    styleIsNotValidError(key, props[key], themeToken);
                }
                vars[tokenVars[key]] = props[key];
                varsTokens[key] = props[key];
            }
        }
    }

    return { vars, varsTokens };
};

export const splitProps = ({ Element, ...restProps }: any): SplitProps => {
    const stylingProps: MapTypes = {};
    const nativeProps: MapTypes = {};
    const tokenProps: MapTypes = {};

    if (Element === 'a') {
        restProps = {
            ...restProps,
            ...linkStyling(restProps),
        };
    }

    for (const key in restProps) {
        if (isAtomsProp(key)) {
            stylingProps[key] = restProps[key];
        } else if (Object.keys(DYNAMIC_TOKENS).includes(key)) {
            tokenProps[key] = restProps[key];
            const numericValues = Object.keys(CSS_NUMERIC_VALUES).includes(key);

            if (typeof restProps[key] === 'object') {
                for (const condition in restProps[key]) {
                    if (typeof restProps[key][condition] === 'number' && !numericValues) {
                        tokenProps[key][condition] = `${restProps[key][condition]}px`;
                    }
                }
            } else if (typeof restProps[key] === 'number' && !numericValues) {
                tokenProps[key] = `${restProps[key]}px`;
            }
        } else {
            nativeProps[key] = restProps[key];
        }
    }

    return {
        stylingProps,
        nativeProps,
        tokenProps,
    };
};

const covertObjectKeysToVars = (object, prop) => {
    const conditions = getConditionsByKey(prop);

    if (!conditions) {
        return {};
    }

    return Object.keys(object).reduce(
        (convertedObject, key) => ({
            ...convertedObject,
            [`${DYNAMIC_TOKENS[prop]}${conditions[key]}`]: object[key],
        }),
        {}
    );
};

export const fillObjectValues = (
    breakpoints: { [k in keyof Breakpoints]: any },
    defaultValue: any,
    values: MapTypes
): MapTypes => {
    let previousValue = defaultValue;
    const object: MapTypes = {};

    Object.keys(breakpoints).forEach((key) => {
        object[key] = values[key] || previousValue;
        previousValue = object[key];
    });

    return object;
};

const convertDefaultObjectToSingleValue = (valueToParse: string | MapTypes) => {
    if (typeof valueToParse === 'object') {
        const keys = Object.keys(valueToParse);
        if (keys.length === 1 && keys[0] === 'default') {
            return valueToParse.default;
        }
    }

    return valueToParse;
};

export const generateCssProperties = (
    valueToParse: string | MapTypes,
    parseProperties: MapTypes,
    value: any,
    defaultValue = 'none'
) => {
    const convertedValue = convertDefaultObjectToSingleValue(valueToParse);

    if (typeof convertedValue !== 'object') {
        return { [parseProperties[convertedValue]]: value };
    }

    const responsiveObject = fillObjectValues(RESPONSIVE_STYLES_BREAKPOINTS, defaultValue, convertedValue);

    return Object.keys(responsiveObject).reduce((pre: any, cur) => {
        const property = responsiveObject[cur];
        const cssProperty = parseProperties[property];

        if (cssProperty) {
            if (!pre[cssProperty]) {
                pre[cssProperty] = {
                    sm: defaultValue,
                    md: defaultValue,
                    lg: defaultValue,
                    xl: defaultValue,
                };
            }

            pre[cssProperty] = {
                ...pre[cssProperty],
                [cur]: value[cur] || value.default || value,
            };
        }

        return pre;
    }, {});
};
