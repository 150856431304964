export const hex2rgb = (hex: string) => {
    let hexValue = hex;

    // In case hex is shortened - #fff or #000
    if (hexValue.length === 4) {
        const repeatedValue = hexValue.slice(1, 4);
        hexValue = `#${repeatedValue}${repeatedValue}`;
    }

    const r = parseInt(hexValue.slice(1, 3), 16);
    const g = parseInt(hexValue.slice(3, 5), 16);
    const b = parseInt(hexValue.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`;
};
