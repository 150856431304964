import type { Color, TextOverflow, FontSize, LineHeight, FontFamily } from '../../components/Box/types';

interface PlaceholderStyles {
    color: Color;
    textOverflow: TextOverflow;
    fontSize: FontSize;
    lineHeight: LineHeight;
    fontFamily: FontFamily;
}

export const placeholderStyles: PlaceholderStyles = {
    color: 'grey_900',
    textOverflow: 'ellipsis',
    fontSize: 'b_md',
    lineHeight: 'b_md',
    fontFamily: 'primary',
};
